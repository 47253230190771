import styles from "./Input.module.css";

export default function Input({ value, onChange, onClick}) {
  return (
    <div className={styles.wrapper}>
      <textarea
        className={styles.text}
        placeholder="请输入你的对话..."
        value={value}
        onChange={onChange}
      />
      <button className={styles.btn} onClick={onClick}>
        Go
      </button>
    </div>
  );
}