import './App.css';
// import XLSX from 'xlsx';
import { read, writeFile, utils, write } from 'xlsx';
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Clear from './components/Clear';
import Input from './components/Input';
import Message from './components/Message';
import History from './components/History';
import Upload from  './components/Upload';


function App() {
  return (
    <Router>
      <div>
        <nav>
          <Link to="/home">Home</Link>
          <Link to="/subpage">Subpage</Link>
          {/*<Link to="/process_file">Process_file</Link>*/}
        </nav>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/subpage" element={<Assistant />} />
          {/*<Route path="/process_file" element={<ProcessFile />} />*/}
        </Routes>
      </div>
    </Router>
  );
}

function Home(){
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const [modelVersion, setModelVersion] = useState("gpt-3.5-turbo");
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
// 输出文本
  const handleSubmit = async () => {
    const userMessage = {
    role: 'user',
    content: input,
    };
    setMessages([...messages, userMessage]);
    console.log(messages)
    await fetch("https://wwwtest-kjjr-lcw.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        // 可用model: gpt-4-1106-preview, gpt-3.5-turbo
        // model: "gpt-3.5-turbo",
        model: modelVersion,
        messages: [...messages, userMessage],
      }),
    })
      .then((data) => data.json())
      .then((data) => {
//         const res = data.choices[0].message.content;
//         setMessages((messages) => [
//           ...messages,
//           {
//             role: "assistant",
//             content: res,
//           },
//         ]);
//         setHistory((history) => [...history, { question: input, answer: res }]);
//         setInput("");
        const assistantMessage = {
          role: "assistant",
          content: data.choices[0].message.content,
        };

        setMessages((messages) => [...messages, assistantMessage]);
        setHistory((history) => [...history, { question: input, answer: assistantMessage.content }]);
        setInput("");
      });
  };
// 历史文本点击
  const handleHistoryClick = (question, answer) => {
    setMessages([
      ...messages,
      { role: 'user', content: question },
      { role: 'assistant', content: answer },
    ]);
  };

  const clearHistory = () => {
    setMessages([]);
    setHistory([]);
  };


  useEffect(() => {
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

// 切换model
const handleModelChange = (event) => {
  console.log('选中的模型:', event.target.value);
  setModelVersion(event.target.value);
};
  return (
    <div className="App">
       <div className="modelSelector">
        <select onChange={handleModelChange} value={modelVersion}>
          <option value="gpt-3.5-turbo">GPT-3.5</option>
          <option value="gpt-4-1106-preview">GPT-4.0</option>
        </select>
      </div>
      <div className="Column">
        <div className="Title">
          <h2>聊天信息</h2>
        </div>
        <div className="Content">
          <div className="ChatContainer">
            <div className="ChatMessages">
              {messages.map((msg, index) => (
                <Message key={index} role={msg.role} content={msg.content} />
              ))}
              <div ref={inputRef}></div>
            </div>
          </div>
        </div>
        <div className="InputSection">
          <Input value={input} onChange={handleInputChange} onClick={handleSubmit}/>
        </div>
      </div>
      <div className="Column">
        <div className="Title">
          <h2>历史记录</h2>
        </div>
        <div className="Content">
          <div className="HistoryContainer">
            <div className="HistorySidebar">

              {history.map((item, index) => (
                <History key={index} question={item.question} onClick={() => handleHistoryClick(item.question, item.answer)} />
              ))}
            </div>
          </div>
        </div>
        <div className="ClearSelection">
          <Clear onClick={clearHistory} />
        </div>

      </div>
    </div>
  );
}

function Assistant(){
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [history, setHistory] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileIDs, setFileIDs] = useState([]);
  let [threadID, setThreadID] = useState('');
  // const [runID, setRunID] = useState('');
  // const [runStatus, setRunStatus] = useState('');
  const inputRef = useRef(null);

  // 选择文件
  const handleFileChange = async (event) => {
    if (event.target.files.length > 0) {
      const files = event.target.files;
      // const csvFiles = [];
      const processedFiles = [];
      for (let i=0; i<files.length;i++){
        const file = files[i];
        // if (file.type === "text/csv"|| file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        //   // csvFiles.push(file);
        // } else {
        //   alert("只能上传CSV和XLSX文件");
        // }
        if (file.type !== "text/csv" && file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        alert("只能上传CSV和XLSX文件");
        continue; // 跳过非CSV/XLSX文件
        }
        const processedFile = await new Promise((resolve, reject) =>{
          const reader = new FileReader();
          reader.onload = (e) => {
            const data = e.target.result;
            const workbook = read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            // 删除A列的内容
            for (let key in sheet) {
              if (key.startsWith('A')) {
                delete sheet[key];
              }
            }

            // 检测B列中的“三栏账”并切分
            const rows = utils.sheet_to_json(sheet, { header: 1 });
            let startIndex = -1;
            for (let i = 0; i < rows.length; i++) {
              if (rows[i][1] === '三栏账') {
                if (startIndex === -1) {
                  startIndex = i;
                } else {
                   // 从第八行开始至下一个“三栏账”之前的内容
                  const endIndex = i-2;
                   if (endIndex - startIndex > 7) { // 确保有超过7行的数据
                    const headerRow = ['三栏账描述', '日期', '单据编号', '摘要', '原币币种', '借方原币金额', '借方本位币金额', '贷方原币金额', '贷方本位币金额', '方向', '原币余额', '本位币余额'];
                    // const newSheetData = [headerRow].concat(rows.slice(startIndex + 8, endIndex));
                    const newSheetData = [headerRow];
                    for (let j = startIndex + 8; j < endIndex; j++) {
                      if (rows[j]) {
                        const newRow = [rows[Math.min(startIndex + 4, endIndex - 1)][2], ...rows[j].slice(1, 12)];
                        // const newRow = new Array(headerRow.length).fill(null);
                        // newRow[0] = rows[Math.min(startIndex + 4, endIndex - 1)][2]; // 保证不超出rows范围
                        newSheetData.push(newRow);
                      }
                    }
                    const newSheet = utils.aoa_to_sheet(newSheetData);
                    const newSheetName = `Sheet${workbook.SheetNames.length + 1}`;
                    utils.book_append_sheet(workbook, newSheet, newSheetName);
                  }
                  startIndex = i;
                }
              }
            }
            // 保存修改后的文件
            writeFile(workbook, `modified_${file.name}`);
             // 将处理后的workbook转换为Blob对象
            const wbout = write(workbook, { bookType: 'xlsx', type: 'binary' });
            const blob = new Blob([s2ab(wbout)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            // 创建一个新的File对象
            const newFile = new File([blob], `modified_${file.name}`, {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            });

            resolve(newFile);
            };
          reader.onerror = (error) => reject(error);
          reader.readAsBinaryString(file);
        });
        processedFiles.push(processedFile)
      }
      setSelectedFile(processedFiles);
    }
  };

  // 辅助函数：将二进制字符串转换为ArrayBuffer
  function s2ab(s) {
    const buffer = new ArrayBuffer(s.length);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buffer;
  }

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };
// 输出文本
  const handleSubmit = async () => {
    const userMessage = {
    role: 'user',
    content: input,
    };
    const newMessage = ([...messages, userMessage]);
    if (fileIDs.length > 0){
      newMessage.pop();
      const userMessage = {
          role: 'user',
          content: input,
          file_ids: [fileIDs]
      };
      newMessage.push(userMessage);
    }
    setMessages(newMessage);
    if(threadID.length<=0){
      const threadResponse = await fetch("https://wwwtest-kjjr-lcw.com/v1/threads", {
        method:"POST",
        headers:{
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1" ,
        },
      });
      const threadData = await threadResponse.json();
      setThreadID((prevState) => threadData.id);
      threadID = setThreadID(threadData.id);
      await fetchThread(threadData.id, userMessage);
    }
    else{
      await fetchThread(threadID, userMessage)
    }
    // let api_url_thread = "https://wwwtest-kjjr-lcw.com/v1/threads/"+ threadID +"/messages"
    // await fetch(api_url_thread, {
    //   method:"POST",
    //   headers:{
    //     Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    //     "Content-Type": "application/json",
    //     "OpenAI-Beta": "assistants=v1" ,
    //   },
    //   body:JSON.stringify(
    //     // "assistant_id": "asst_jut4xEuEqde2lJmroHkdBWEa",
    //     userMessage
    //   ),
    // });
    // api_url_thread = "https://wwwtest-kjjr-lcw.com/v1/threads/"+ threadID +"/runs"
    // await fetch(api_url_thread,{
    //   method:"POST",
    //   headers:{
    //     Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    //     "Content-Type": "application/json",
    //     "OpenAI-Beta": "assistants=v1" ,
    //   },
    //   body:JSON.stringify({
    //     "assistant_id": "asst_jut4xEuEqde2lJmroHkdBWEa",
    //   }),
    // })
    //   .then((data_run) => data_run.json())
    //   .then((data_run) => {
    //     // setRunID(data_run.id)
    //     console.log('Thread ID:', threadID, 'Run ID:', data_run.id);
    //     fetchRunStatus(threadID, data_run.id);
    //   });
    // api_url_thread = `https://wwwtest-kjjr-lcw.com/v1/threads/${threadID}/messages`;
    // await fetch(api_url_thread,{
    //   method:"GET",
    //   headers:{
    //     Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    //     "Content-Type": "application/json",
    //     "OpenAI-Beta": "assistants=v1" ,
    //   },
    // })
    //   .then((data_message) => data_message.json())
    //   .then((data_message) => {
    //     console.log("API Response:", data_message);
    //     const assistantMessage = {
    //       role: "assistant",
    //       content: data_message.data[0].content[0].text.value,
    //     };
    //     setMessages((messages) => [...messages, assistantMessage]);
    //     setHistory((history) => [...history, { question: input, answer: assistantMessage.content }]);
    //     setInput("");
    //   })
    //   .catch((error) => {
    //     console.error("API请求错误:", error); // 捕获任何错误
    //   });

//     await fetch("https://wwwtest-kjjr-lcw.com/v1/assistants/asst_jut4xEuEqde2lJmroHkdBWEa", {
//       method: "POST",
//       headers: {
//         'OpenAI-Beta': 'assistants=v1',
//         Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({
//         // 可用model: gpt-4-1106-preview, gpt-3.5-turbo
//         // model: "gpt-3.5-turbo",
//         messages: [...messages, userMessage],
//       }),
//     })
//       .then((data) => data.json())
//       .then((data) => {
//         console.log("API Response:", data);
//         const assistantMessage = {
//           role: "assistant",
//           content: data.choices[0].message.content,
//         };
//         setMessages((messages) => [...messages, assistantMessage]);
//         setHistory((history) => [...history, { question: input, answer: assistantMessage.content }]);
//         setInput("");
//       }).catch((error) => {
//   console.error("API请求错误:", error); // 捕获任何错误
// });
  };
  // 访问线程对应网址
  const fetchThread = async (currentThreadID, currentUserMessage) => {
    try{
      let api_url_thread = "https://wwwtest-kjjr-lcw.com/v1/threads/"+ currentThreadID +"/messages"
      await fetch(api_url_thread, {
        method:"POST",
        headers:{
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1" ,
        },
        body:JSON.stringify(
          // "assistant_id": "asst_jut4xEuEqde2lJmroHkdBWEa",
          currentUserMessage
        ),
      });
      api_url_thread = "https://wwwtest-kjjr-lcw.com/v1/threads/"+ currentThreadID +"/runs"
      const runPostResponse = await fetch(api_url_thread,{
        method:"POST",
        headers:{
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1" ,
        },
        body:JSON.stringify({
          "assistant_id": "asst_jut4xEuEqde2lJmroHkdBWEa",
        }),
      });
      const runPostData = await runPostResponse.json();
      console.log(runPostData)
      console.log('Thread ID:', currentThreadID, 'Run ID:', runPostData.id);
      await fetchRunStatus(currentThreadID, runPostData.id);
      // await fetch(api_url_thread,{
      //   method:"POST",
      //   headers:{
      //     Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
      //     "Content-Type": "application/json",
      //     "OpenAI-Beta": "assistants=v1" ,
      //   },
      //   body:JSON.stringify({
      //     "assistant_id": "asst_jut4xEuEqde2lJmroHkdBWEa",
      //   }),
      // })
      //   .then((data_run) => data_run.json())
      //   .then((data_run) => {
      //     // setRunID(data_run.id)
      //     console.log('Thread ID:', currentThreadID, 'Run ID:', data_run.id);
      //     fetchRunStatus(currentThreadID, data_run.id);
      //   });
      console.log(1)
      api_url_thread = `https://wwwtest-kjjr-lcw.com/v1/threads/${currentThreadID}/messages`;
      await fetch(api_url_thread,{
        method:"GET",
        headers:{
          Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          "Content-Type": "application/json",
          "OpenAI-Beta": "assistants=v1" ,
        },
      })
        .then((data_message) => data_message.json())
        .then((data_message) => {
          console.log("API Response:", data_message);
          const assistantMessage = {
            role: "assistant",
            content: data_message.data[0].content[0].text.value,
          };
          setMessages((messages) => [...messages, assistantMessage]);
          setHistory((history) => [...history, { question: input, answer: assistantMessage.content }]);
          setInput("");
        })
        .catch((error) => {
          console.error("API请求错误:", error); // 捕获任何错误
        });
    } catch (error){
      console.error("API请求错误:", error);
    }
  }
  // 查询run的状态
  const fetchRunStatus = async (currentThreadId, currentRunId) => {
    return new Promise((resolve, reject)=>{
      const checkStatus = async () =>{
        try {
          console.log(currentThreadId, currentRunId)
          await fetch(`https://wwwtest-kjjr-lcw.com/v1/threads/${currentThreadId}/runs/${currentRunId}`,{
          method:"GET",
          headers:{
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            "Content-Type": "application/json",
            "OpenAI-Beta": "assistants=v1" ,
          },
        })
          .then((data) => data.json())
          .then((data) => {
            const currentRunStatus = data.status;
            // setRunStatus(currentRunStatus);
            if (currentRunStatus === 'completed' || currentRunStatus === 'expired') {
            // 如果 run.status 为 'completed' 或 'expired'，停止轮询
            // 例如：执行操作 A 或操作 B
            console.log(`Run 已${currentRunStatus === 'completed' ? '完成' : '过期'}，执行操作 A 或操作 B`);
            resolve();
            } else {
            // 如果 run.status 不是 'completed' 或 'expired'，继续轮询
            setTimeout(checkStatus, 1000); // 1秒后再次获取 run.status
            }
          });
        } catch (error) {
          console.error('发生错误:', error);
          reject(error);
        }
      };
      checkStatus();
    });
  };

// 历史文本点击
  const handleHistoryClick = (question, answer) => {
    setMessages([
      ...messages,
      { role: 'user', content: question },
      { role: 'assistant', content: answer },
    ]);
  };

  const clearHistory = () => {
    setMessages([]);
    setHistory([]);
  };


  useEffect(() => {
    inputRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);
// 上传文件
  const handleUpload = async () => {
    let newFileIDs = [];
    if (selectedFile) {
      for (const file of selectedFile){
        const formData = new FormData();
        formData.append('file', file);
        formData.append('purpose', "assistants")
        try {
          const response_upload_file = await fetch('https://wwwtest-kjjr-lcw.com/v1/files', {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
            },
            body: formData
          });

          const result_upload_file = await response_upload_file.json();
          if (response_upload_file.ok) {
            console.log('文件上传成功, 文件ID:', result_upload_file.id);// 获取上传的文件的ID
            newFileIDs.push(result_upload_file.id);
            try{
              const response_upload_file_to_assistant = await fetch('https://wwwtest-kjjr-lcw.com/v1/assistants/asst_jut4xEuEqde2lJmroHkdBWEa/files',{
                method: 'POST',
                headers:{
                  'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
                  "Content-Type": "application/json",
                  "OpenAI-Beta": "assistants=v1" ,
                },
                body: JSON.stringify({
                  'file_id': result_upload_file.id,
                }),
              });
              const result_upload_file_to_assistant = await response_upload_file_to_assistant.json();
              if (response_upload_file_to_assistant.ok){
                console.log('文件 ', result_upload_file_to_assistant.id,' 成功上传至助手')
              }
            }catch(error){
              console.error('绑定文件与助手过程中发生错误', error)
            }
          } else {
            console.error('上传失败:', result_upload_file);
          }

        } catch (error) {
          console.error('上传时发生错误:', error);
        }
      }
      setFileIDs(newFileIDs);
    }
  };
  return (
    <div className="App">
       <div className="inputContainer">
         {/*<div className="upload_button">选择文件</div>*/}
         <div style={{position: 'relative',  inset: '0px auto auto 0px'}}>
           <input
            type="file"
            multiple
            accept=".csv, .xlsx"
            id = "fileInput"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
           <label htmlFor="fileInput" className="upload_button">选择文件</label>
         </div>
         <Upload onClick={handleUpload} />
       </div>
      <div className="Column">
        <div className="Title">
          <h2>聊天信息</h2>
        </div>
        <div className="Content">
          <div className="ChatContainer">
            <div className="ChatMessages">
              {messages.map((msg, index) => (
                <Message key={index} role={msg.role} content={msg.content} />
              ))}
              <div ref={inputRef}></div>
            </div>
          </div>
        </div>
        <div className="InputSection">
          <Input value={input} onChange={handleInputChange} onClick={handleSubmit}/>
        </div>
      </div>
      <div className="Column">
        <div className="Title">
          <h2>历史记录</h2>
        </div>
        <div className="Content">
          <div className="HistoryContainer">
            <div className="HistorySidebar">

              {history.map((item, index) => (
                <History key={index} question={item.question} onClick={() => handleHistoryClick(item.question, item.answer)} />
              ))}
            </div>
          </div>
        </div>
        <div className="ClearSelection">
          <Clear onClick={clearHistory} />
        </div>

      </div>
    </div>
  );
}

// function ProcessFile(){
//   const [selectedFile, setSelectedFile] = useState(null);
//
//   // 选择文件
//   const handleFileChange = (event) => {
//     if (event.target.files.length > 0) {
//       const files = event.target.files;
//       const csvFiles = [];
//       for (let i=0; i<files.length;i++){
//         const file = files[i];
//         if (file.type === "text/csv"|| file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
//           csvFiles.push(file);
//         } else {
//           alert("只能上传CSV和XLSX文件");
//         }
//       }
//       setSelectedFile(csvFiles);
//     }
//   };
//
//   // 上传文件
//     const handleUpload = async () => {
//     let newFileIDs = [];
//     if (selectedFile) {
//       for (const file of selectedFile){
//         const formData = new FormData();
//         formData.append('file', file);
//         try {
//           const response_upload_file = await fetch('https://8.141.4.139', {
//             method: 'POST',
//             headers: {
//               'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
//             },
//             body: formData
//           });
//
//           const result_upload_file = await response_upload_file.json();
//           if (response_upload_file.ok) {
//             console.log('文件上传成功, 文件ID:', result_upload_file.id);// 获取上传的文件的ID
//             newFileIDs.push(result_upload_file.id);
//             try{
//               const response_upload_file_to_assistant = await fetch('https://wwwtest-kjjr-lcw.com/v1/assistants/asst_jut4xEuEqde2lJmroHkdBWEa/files',{
//                 method: 'POST',
//                 headers:{
//                   'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
//                   "Content-Type": "application/json",
//                   "OpenAI-Beta": "assistants=v1" ,
//                 },
//                 body: JSON.stringify({
//                   'file_id': result_upload_file.id,
//                 }),
//               });
//               const result_upload_file_to_assistant = await response_upload_file_to_assistant.json();
//               if (response_upload_file_to_assistant.ok){
//                 console.log('文件 ', result_upload_file_to_assistant.id,' 成功上传至助手')
//               }
//             }catch(error){
//               console.error('绑定文件与助手过程中发生错误', error)
//             }
//           } else {
//             console.error('上传失败:', result_upload_file);
//           }
//
//         } catch (error) {
//           console.error('上传时发生错误:', error);
//         }
//       }
//       setFileIDs(newFileIDs);
//     }
//   };
// }

export default App
